// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $new-web-registration-primary: mat.define-palette(mat.$indigo-palette);
// $new-web-registration-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $new-web-registration-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $new-web-registration-theme: mat.define-light-theme((
//   color: (
//     primary: $new-web-registration-primary,
//     accent: $new-web-registration-accent,
//     warn: $new-web-registration-warn,
//   ),
//   typography: mat.define-typography-config(),
//   density: 0
// ));

:root {
  --primary-blue: #005095;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($new-web-registration-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "bootstrap/scss/bootstrap-grid";

// Your custom styles
html,
body {
  height: 100%;
}

.full-width {
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.link-tag {
  text-decoration: none;
  cursor: pointer;
  display: inline;
  margin-top: 2px;
}

.custom-card {
  border-radius: 10px !important;
  background-color: #fff !important;
  background-color: var(--mdc-theme-surface, #fff) !important;
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  padding: 20px !important;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
}

.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important
}

.text-right {
  float: right;
}

.mr-3 {
  margin-right: 3px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-10 {
  margin-right: 10px;
}


.mat-snack-bar-container.success-snackbar {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.mat-snack-bar-container.error-snackbar {
  background-color: #f44336 !important;
  color: #fff !important;
}

.custom-snackbar-position {
  margin-bottom: 50px;
}
